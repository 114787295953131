import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

export const query = graphql`
    {
        allAirtable(filter: {table: {eq: "Limir"}}, sort: {order: ASC, fields: data___Navn}) {
            nodes {
              data {
                Navn                
              }
            }
        }
    }
`;

export default ({ data }) => (
    <Layout>
        <h1>Limir</h1>        
        <ul>
            {data.allAirtable.nodes.map(node => (
                <li key={node.recordId}>
                    {node.data.Navn}
                </li>
            ))}
        </ul>
    </Layout>
)